const Config = {
    ADMIN: false,
    LOCALIMG:false,
    LOCALADDRESS:"https://localhost:7268",
    robot:true,
    localstorageVersion:"2"
}


export {
    Config
}