import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import EmailOutlineIcon from 'mdi-react/EmailOutlineIcon';
import CardAccountDetailsIcon from 'mdi-react/PasswordOutlineIcon';
import Signin from '../../assets/blue.png'
import Logo from '../../assets/blue.png'
import { postRequest } from '../service';



const Registration = () => {
    const history = useHistory();

    const [email, setEmail] = useState("example@soneri.com");
    const [password, setpassword] = useState("");
    const [repassword, setrepassword] = useState("");
    const [firstname, setfirstname] = useState("");
    const [lastname, setlastname] = useState("");
    const [Token, settoken] = useState("");
    const [Error, setError] = useState("");

    useEffect(() => {
        var urlString = window.location.href;
        let params = (new URL(urlString)).searchParams;
        setEmail(params.get('Email'))
        settoken(params.get('Token'))
    }, [])

    const [isLoaded, setisLoaded] = useState(true);
    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    async function submit(event) {
        event.preventDefault()
        setError("");
        if (password !== repassword) {
            alert("Password Don't Match.")
            return;
        }
        if (validateEmail(email)) {
            var data = {
                Email: email,
                Password: password,
                FullName: firstname + " " + lastname,
                Token: Token
            };
            var t = await postRequest("api/dashboard/Auth/Register", data);
            if (t.StatusCode === 0) {
                setisLoaded(true);
                history.push("/admin/login");
            } else {
                setError(t.StatusMessage);
            }
        } else {
            alert("Invalid email address.");
            setisLoaded(true);

        }

    }
    return (
        !isLoaded ? (
            <div style={{ textAlign: "center" }}>
                {/* <h3 style={{alignSelf:"center",margin:"auto"}}>Please Wait..</h3> */}
                <div className={`load${true ? '' : ' loaded'}`}>

                    <div className="load__icon-wrap">
                        <svg className="load__icon">
                            <path fill="#2c2d6e" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                        </svg>
                    </div>
                </div>
            </div>
        ) :
            <div className="row col-md-12 col-sm-12 col-lg-12">

                <div className="row col-md-12 col-sm-12 col-lg-12" >
                    <div className="col-md-6 col-lg-6 col-sm-12" id="mobidiv" style={{ margin: "auto" }}>

                        <div style={{ margin: 5 }}>
                        </div>
                        <img src={Signin} style={{ height: "10vh", objectFit: "contain" }} />
                        <div style={{ textAlign: "center" }} >
                            <img src={Logo} id="faysallogo" style={{ display: "none" }} />
                        </div>
                        <form className="form" onSubmit={submit} style={{ marginTop: 30 }}>
                            <h3>Registration </h3>
                            <br />
                            {Error !== "" ?
                                <label style={{ color: "red" }}>{Error}</label> : <></>
                            }
                            <br />
                            <div className="form__form-group" style={{ marginTop: 20 }}>
                                <span className="form__form-group-label" style={{ marginLeft: 40, fontSize: 15, fontWeight: 400, color: "black" }}>Email</span>
                                <div className="form__form-group-field">
                                    <div className="form__form-group-icon">
                                        <EmailOutlineIcon />
                                    </div>
                                    <input
                                        style={{ borderColor: "var(--faysal)", borderRadius: 5, height: 35, fontSize: 14 }}
                                        name="name"
                                        type="email"
                                        autoComplete="off"
                                        placeholder="Input your email here"
                                        required
                                        disabled
                                        value={email}
                                        onChange={(txt) => { setEmail(txt.target.value); }}
                                    />
                                </div>
                            </div>
                            <div className="form__form-group" >
                                <span className="form__form-group-label" style={{ marginLeft: 40, fontSize: 15, fontWeight: 400, color: "black" }}>First Name</span>
                                <div className="form__form-group-field">
                                    <div className="form__form-group-icon">
                                        <CardAccountDetailsIcon />
                                    </div>
                                    <input
                                        style={{ borderColor: "var(--faysal)", borderRadius: 5, height: 35, fontSize: 14 }}
                                        name="name"
                                        type="text"
                                        placeholder="Input your First Name"
                                        required
                                        autoComplete="off"
                                        value={firstname}
                                        onChange={(txt) => { setfirstname(txt.target.value); }}
                                    />
                                </div>
                            </div>
                            <div className="form__form-group" >
                                <span className="form__form-group-label" style={{ marginLeft: 40, fontSize: 15, fontWeight: 400, color: "black" }}>Last Name</span>
                                <div className="form__form-group-field">
                                    <div className="form__form-group-icon">
                                        <CardAccountDetailsIcon />
                                    </div>
                                    <input
                                        style={{ borderColor: "var(--faysal)", borderRadius: 5, height: 35, fontSize: 14 }}
                                        name="name"
                                        type="text"
                                        placeholder="Input your Last Name"
                                        required
                                        autoComplete="off"
                                        value={lastname}
                                        onChange={(txt) => { setlastname(txt.target.value); }}
                                    />
                                </div>
                            </div>
                            <div className="form__form-group" >
                                <span className="form__form-group-label" style={{ marginLeft: 40, fontSize: 15, fontWeight: 400, color: "black" }}>Password</span>
                                <div className="form__form-group-field">
                                    <div className="form__form-group-icon">
                                        <CardAccountDetailsIcon />
                                    </div>
                                    <input
                                        style={{ borderColor: "var(--faysal)", borderRadius: 5, height: 35, fontSize: 14 }}
                                        name="name"
                                        type="password"
                                        placeholder="Enter your password"
                                        required
                                        minLength={6}
                                        maxLength={16}
                                        autoComplete="off"
                                        value={password}
                                        onChange={(txt) => { setpassword(txt.target.value); }}
                                    />
                                </div>
                            </div>
                            <div className="form__form-group" >
                                <span className="form__form-group-label" style={{ marginLeft: 40, fontSize: 15, fontWeight: 400, color: "black" }}>Re-Password</span>
                                <div className="form__form-group-field">
                                    <div className="form__form-group-icon">
                                        <CardAccountDetailsIcon />
                                    </div>
                                    <input
                                        style={{ borderColor: "var(--faysal)", borderRadius: 5, height: 35, fontSize: 14 }}
                                        name="name"
                                        type="password"
                                        placeholder="Re-enter your password"
                                        required
                                        minLength={6}
                                        maxLength={16}
                                        autoComplete="off"
                                        value={repassword}
                                        onChange={(txt) => { setrepassword(txt.target.value); }}
                                    />
                                </div>
                            </div>

                            <button className={"btn"} type={"submit"} style={{ marginLeft: 30, backgroundColor: "var(--faysal)", color: "white", fontWeight: "bold" }}>Register</button>
                        </form>
                    </div>
                </div>
            </div>
    )
}

export default Registration;

